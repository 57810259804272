import CheckboxSelectAll from "stimulus-checkbox-select-all"
import Rails from '@rails/ujs';

export default class extends CheckboxSelectAll {
    static targets = [
        "bulkActionsMenu",
        "checkedCounter",
        "updateAsUnpaidAction",
        "updateAsPaidAction"
    ]

    static values = {
        currentStatus: String,
    }

    connect() {
        super.connect()
        // Get all checked checkboxes
        this.checked
        // Get all unchecked checkboxes
        this.unchecked
    }

    handleSingleSelect(event) {
        if(this.checked.length > 0) {
            this.showMenu();
            this.showButtonMenuAction(this.checked)
        } else {
            this.hideMenu();
        }
        this.updateCheckedCount();
    }

    handleSelectAll(event) {
        if(event.target.checked) {
            let self = this;
            self.updateCheckedCount(this.checked.length+this.unchecked.length);
            self.showMenu();
            self.showButtonMenuAction(this.checkboxTargets);
        } else {
            this.hideMenu();
            this.updateCheckedCount();
        }
    }

    updateAsClosed(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_closed', this.checked, this.getRedirectUrlParam(event));
        }
    }

    updateAsPaid(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_paid', this.checked, this.getRedirectUrlParam(event));
        }
    }

    updateAsUnpaid(event) {
        event.preventDefault();
        if(window.confirm(this.getConfirmMsgParam(event)) && this.checked.length > 0) {
            this.doAjaxRequest('update_as_unpaid', this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsArchive(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_archive", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsUnarchive(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_unarchive", this.checked, this.getRedirectUrlParam(event));
        }
    }

    showModalBulkUpdateAsPaid(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.doShowModalAjaxRequest("bulk_update_as_paid", this.checked, this.getRedirectUrlParam(event));
        }
    }

    downloadCsvInvoicesDataSpedy(event) {
        event.preventDefault();
        if(this.checked.length > 0) {
            this.openNewWindowWithAjaxRequestBlank("download_csv_invoices_data_spedy", this.checked);
        }
    }

    showMenu() {
        this.bulkActionsMenuTarget.classList.remove("d-none");
    }

    hideMenu() {
        this.bulkActionsMenuTarget.classList.add("d-none");
    }

    showButtonMenuAction(checkboxes) {
        if (this.currentStatusValue=='paid') {
            this.showActionUpdateAsUnpaid(checkboxes)
        }

        if (this.currentStatusValue=='closed') {
            this.showActionUpdateAsPaid(checkboxes)
        }
    }

    updateCheckedCount(count) {
        this.checkedCounterTarget.innerHTML = count || this.checked.length;
    }

    doAjaxRequest(action, checkboxes, redirectUrl) {
        let data = new FormData();
        checkboxes.forEach((checkbox) => data.append("bulk_ids[]", checkbox.value));
        data.append("redirect_url", redirectUrl);
        Rails.ajax({
            type: "PUT",
            url: 'invoices/bulk/'+action,
            data: data
        });
    }

    doShowModalAjaxRequest(action, checkboxes, redirectUrl) {
        let params = new URLSearchParams();
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        params.append("redirect_url", redirectUrl);

        Rails.ajax({
            type: "GET",
            url: 'invoices/bulk/'+action,
            data: params
        });
    }

    openNewWindowWithAjaxRequestBlank(action, checkboxes) {
        let params = new URLSearchParams();
        checkboxes.forEach((checkbox) => params.append("bulk_ids[]", checkbox.value));
        let url = 'invoices/bulk/'+action + '?' + params.toString();
        // Abre uma nova janela ou aba com a URL da solicitação
        window.open(url, '_blank');
    }

    getRedirectUrlParam(event) {
        return event.currentTarget.dataset['redirectUrlParam'];
    }

    getConfirmMsgParam(event) {
        return event.currentTarget.dataset['confirmMsgParam'];
    }

    showActionUpdateAsUnpaid(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['disableUpdateAsUnpaid']==="true";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.updateAsUnpaidActionTarget);
        } else {
            this.enableDropdownAction(this.updateAsUnpaidActionTarget);
        }
    }

    showActionUpdateAsPaid(checkboxes) {
        let notShow = false;
        let breakForEach = false;

        (checkboxes).forEach((button) => {
            if (!breakForEach) {
                notShow = button.dataset['disableUpdateAsPaid']==="true";
                if(notShow) breakForEach = true;
            }
        });

        if (notShow) {
            //desabilitar
            this.disableDropdownAction(this.updateAsPaidActionTarget);
        } else {
            this.enableDropdownAction(this.updateAsPaidActionTarget);
        }
    }

    disableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.add("d-none");
        disabled[0]?.classList.remove("d-none");
    }

    enableDropdownAction(target) {
        let enabled = target?.getElementsByClassName("enabled-option");
        let disabled = target?.getElementsByClassName("disabled-option");

        enabled[0]?.classList.remove("d-none");
        disabled[0]?.classList.add("d-none");
    }

}